<!--
 * @Description: 全部任务单
 * @Author: ChenXueLin
 * @Date: 2021-10-13 19:26:59
 * @LastEditTime: 2022-10-18 09:16:51
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="taskNumber">
              <el-input
                v-model="searchForm.taskNumber"
                placeholder="任务单号"
                title="任务单号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <input-select
                v-model="searchForm.corpName"
                :data="corpList"
                placeholder="客户名称"
                title="客户名称"
                clearable
                virtual
                remote
                :is-title="true"
                @filterChange="handleLoadCorpFilter"
                :props="{
                  id: 'corpName',
                  label: 'corpName'
                }"
              ></input-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpAttrId">
              <e6-vr-select
                v-model="searchForm.corpAttrId"
                :data="corpAtrrList"
                placeholder="客户属性"
                :loading="selectLoading"
                title="客户属性"
                :props="{
                  id: 'id',
                  label: 'attrValueDesc'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskSecondClass">
              <e6-vr-select
                v-model="searchForm.taskSecondClass"
                :data="taskTypeList"
                placeholder="任务单类型"
                title="任务单类型"
                :loading="selectLoading"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskStatusList">
              <e6-vr-select
                v-model="searchForm.taskStatusList"
                :data="workStatusList"
                placeholder="任务单状态"
                title="任务单状态"
                clearable
                :loading="selectLoading"
                multiple
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="roleGroup">
              <e6-vr-select
                v-model="searchForm.roleGroup"
                :data="roleGroupList"
                placeholder="首次联系人类型"
                title="首次联系人类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="projectId">
              <e6-vr-select
                v-model="searchForm.projectId"
                :data="projectList"
                :loading="selectLoading"
                placeholder="所属项目"
                title="所属项目"
                clearable
                :props="{
                  id: 'projectId',
                  label: 'projectName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaIdList">
              <e6-vr-select
                v-model="searchForm.areaIdList"
                :data="areaList"
                :loading="selectLoading"
                placeholder="所属大区"
                title="所属大区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="cityIdList">
              <e6-vr-select
                v-model="searchForm.cityIdList"
                :data="cityList"
                :loading="selectLoading"
                placeholder="所属片区"
                title="所属片区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="addressName">
              <el-input
                v-model="searchForm.addressName"
                placeholder="所属地区"
                title="所属地区"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="场景名称"
                title="场景名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeList"
                :loading="selectLoading"
                placeholder="场景类型"
                title="场景类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="labelType">
              <e6-vr-select
                v-model="searchForm.labelType"
                :data="workTagsList"
                placeholder="标签选择"
                title="标签选择"
                clearable
                :loading="selectLoading"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="expectTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="要求完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.expectTime"
                title="要求完成时间"
                :picker-options="pickerOptions('searchForm.expectTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="要求完成时间（始）"
                end-placeholder="要求完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="finishTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="任务完成时间"
                ref="effectiveDatePicker"
                v-model="searchForm.finishTime"
                title="任务完成时间"
                :picker-options="pickerOptions('searchForm.finishTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="任务完成时间（始）"
                end-placeholder="任务完成时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-history" title="历史记录" @click="toRecord"></i>
          <i class="e6-icon-add_line" title="创建工单" @click="createTask"></i>
          <i
            class="e6-icon-prohibit_line"
            title="批量关闭任务单"
            @click="closeTask"
          ></i>
          <i class="e6-icon-export_line" title="导出" @click="handleExport"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <div
                class="table-label"
                v-if="column.fieldName === 'tagValue' && row[column.fieldName]"
              >
                <span
                  class="label-box"
                  v-for="(item, index) in row.tagValue"
                  :key="index"
                >
                  {{ item }}
                </span>
              </div>
              <span
                v-else-if="column.fieldName === 'taskStatusName'"
                :class="[
                  'status',
                  row.taskStatus == 3 || row.taskStatus == 4
                    ? 'finish'
                    : row.taskStatus === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                {{ row[column.fieldName] }}
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <!-- 创建工单弹框 -->
      <add-task-dialog
        :showAdd="showAdd"
        @closeDialog="closeDialog"
      ></add-task-dialog>
      <!-- 关闭工单弹框 -->
      <close-task-dialog
        :closeVisible="closeVisible"
        :ignoreCount="ignoreCount"
        :closeCount="closeCount"
        @handleClose="handleClose"
        @confirm="closeTaskReq"
      ></close-task-dialog>
    </div>
    <!-- 关闭任务单 -->
    <close-task-item-dialog
      :closeTaskItemDialog="closeTaskItemDialog"
      @handleClose="handleClose"
      :taskIdList="taskIdList"
      dialogType="2"
      @refresh="queryList"
    ></close-task-item-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { exportData } from "@/utils/download";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import AddTaskDialog from "@/components/workOrderManage/addTaskDialog.vue";
import closeTaskDialog from "@/components/workOrderManage/closeTaskDialog.vue";
import { printError } from "@/utils/util";
import closeTaskItemDialog from "@/components/workOrderManage/closeTaskItemDialog.vue";
import {
  getAllTaskList,
  getProjectSelect,
  findDownList,
  findCorpAttr,
  closeTask,
  closeTaskValid,
  exportTaskList,
  getListAreaAll
} from "@/api";

export default {
  name: "allTaskList",
  data() {
    return {
      showAdd: false,
      searchForm: {
        taskNumber: "", //任务单号
        corpName: "", //客户名称
        tasktype: "", //任务单类型
        corpAttrId: "", //客户属性
        taskStatusList: ["1", "2"], //任务单状态
        taskSecondClass: [], //任务单类型
        projectId: "", //所属项目
        areaNameList: [], //所属大区
        areaIdList: [],
        cityIdList: [],
        cityNameList: [], //所属片区
        roleGroup: "", //首次联系人类型
        addressName: "", //所属地区
        createByName: "", //创建人
        sceneName: "", //场景名称
        sceneType: "", //场景类型
        labelType: "", //标签
        expectTime: [], //要求完成时间
        expecttimeStart: "", //期待解决时间开始
        expecttimeend: "", //期待解决时间结束
        createTime: [], //创建时间
        createdTimeStart: "", //期待解决时间开始
        createdTimeEnd: "", //期待解决时间结束
        finishTime: [], //完成时间
        finishTimeStart: "", //期待解决时间开始
        finishTimeEnd: "", //期待解决时间结束
        pageIndex: 1,
        pageSize: 20
      },
      roleGroupList: [],
      selectLoading: false,
      loading: false,
      areaList: [], //大区
      cityList: [], //片区
      addressList: [], //地区
      projectList: [], //项目列表
      corpAtrrList: [], //客户属性下拉框
      taskTypeList: [], //工单类型
      workStatusList: [], //工单状态下拉框
      sceneTypeList: [], //场景类型
      workTagsList: [], //标签
      corpList: [], //客户名称下拉框
      total: 0,
      columnData: [
        {
          fieldName: "tagValue",
          display: true,
          fieldLabel: "标签",
          width: 210,
          disabled: false,
          fixed: false,
          align: "center",
          class: ""
        },
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "任务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskStatusName",
          display: true,
          fieldLabel: "任务单状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
          mode: "color",
          color: "#79a3f2"
        },
        {
          fieldName: "expecttime",
          display: true,
          fieldLabel: "要求完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "任务单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "所属大区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cityName",
          display: true,
          fieldLabel: "所属片区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "addressName",
          display: true,
          fieldLabel: "所属地区",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskLevel",
          display: true,
          fieldLabel: "任务级别",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createByName",
          display: true,
          fieldLabel: "创建人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstContact",
          display: true,
          fieldLabel: "首次联系人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstContactTime",
          display: true,
          fieldLabel: "首次联系时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneNames",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpAttrName",
          display: true,
          fieldLabel: "客户属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "projectName",
          display: true,
          fieldLabel: "所属项目",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "unassigned",
          display: true,
          fieldLabel: "任务项总数",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "nunassigned",
          display: true,
          fieldLabel: "未分配",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAssig",
          display: true,
          fieldLabel: "待指派",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitAccept",
          display: true,
          fieldLabel: "待接受",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitWork",
          display: true,
          fieldLabel: "待作业",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workIng",
          display: true,
          fieldLabel: "作业中",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitVerify",
          display: true,
          fieldLabel: "待审核",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "normalFinished",
          display: true,
          fieldLabel: "已完成",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinished",
          display: true,
          fieldLabel: "异常完成",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "dispatcherGroup",
          display: true,
          fieldLabel: "调度员服务详情次数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerGroup",
          display: true,
          fieldLabel: "工程师服务详情次数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTime",
          display: true,
          fieldLabel: "任务完结时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      closeVisible: false, //关闭弹框
      ignoreCount: 0, //不能关闭的任务单
      closeCount: 0, //可以关闭的任务单
      closeTaskItemDialog: false,
      taskIdList: []
    };
  },
  components: {
    AddTaskDialog,
    closeTaskDialog,
    closeTaskItemDialog
  },
  mixins: [listPage, listPageReszie, base],
  watch: {
    // 期望解决时间
    "searchForm.expectTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.expectTime = this.searchForm.expectTime.reverse();
        }
        this.searchForm.expecttimeStart = val[0] || "";
        this.searchForm.expecttimeend = val[1] || "";
      }
    },
    // 创建时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    },
    // 完成时间
    "searchForm.finishTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.finishTime = this.searchForm.finishTime.reverse();
        }
        this.searchForm.finishTimeStart = val[0] || "";
        this.searchForm.finishTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },

  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          getProjectSelect({}),
          findDownList([
            "taskSecondClass",
            "workStatus",
            "sceneType",
            "workTags",
            "roleGroup"
          ]),
          findCorpAttr({ id: null }),
          getListAreaAll({ areaType: 1 }),
          getListAreaAll({ areaType: 2 })
        ];
        let [
          projectRes,
          taskTypeRes,
          corpAttrRes,
          areaRes,
          cityRes
        ] = await Promise.all(promiseList);
        //获取项目下拉框
        this.projectList = this.getFreezeResponse(projectRes, {
          path: "data"
        });
        //首次联系人类型
        this.roleGroupList = this.getFreezeResponse(taskTypeRes, {
          path: "data.roleGroup"
        });
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        //标签
        this.workTagsList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workTags"
        });
        //工单状态
        this.workStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.workStatus"
        });
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.sceneType"
        });
        //获取客户属性
        this.corpAtrrList = this.getFreezeResponse(corpAttrRes, {
          path: "data"
        });
        //获取大区
        this.areaList = this.getFreezeResponse(areaRes, {
          path: "data"
        });
        //获取片区
        this.cityList = this.getFreezeResponse(cityRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },

    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        // this.searchForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //导出工单
    handleExport() {
      exportData(this, exportTaskList);
    },
    //获取片区大区名字集合
    intersection(a, b) {
      //大区片区信息集合，b片区大区ID集合
      const newList = a.filter(item => b.includes(item.areaId));
      let data = [];
      if (newList.length) {
        data = newList.map(item => item.areaName);
      }
      return data;
    },
    //查询全部任务单
    async queryList() {
      try {
        this.loading = true;
        this.searchForm.cityNameList = this.intersection(
          this.cityList,
          this.searchForm.cityIdList
        );
        this.searchForm.areaNameList = this.intersection(
          this.areaList,
          this.searchForm.areaIdList
        );
        let res = await getAllTaskList(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.tagValue = item.tagValue ? item.tagValue.split(",") : [];
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 日志
    toRecord() {
      this.routerPush({
        name: "taskOrderRecord",
        params: {
          refresh: true
        }
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "taskListManage/detail",
          params: {
            refresh: true,
            taskId: row.taskId
          }
        });
      }
    },
    toDetail() {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true
        }
      });
    },
    //创建任务单
    createTask() {
      this.showAdd = true;
    },
    //关闭弹框
    closeDialog() {
      this.showAdd = false;
    },
    /********批量关闭操作************/
    //点击批量关闭任务单
    //关闭工单
    closeTask() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选要关闭的任务单");
        return;
      }
      this.taskIdList = this.selColumn.map(item => {
        return item.taskId;
      });
      this.closeTaskItemDialog = true;
      // this.closeTaskValidReq();
    },
    //关闭工单检验
    async closeTaskValidReq() {
      try {
        let parmas = this.selColumn.map(item => {
          return item.taskId;
        });
        let res = await closeTaskValid({ taskIdList: parmas });
        this.closeVisible = true;
        this.ignoreCount = res.data.unTaskIdList.length; //不能关闭的单
        this.unTaskIdList = res.data.unTaskIdList;
        this.closeCount = res.data.taskIdList.length; //能关闭的单
        this.taskIdList = res.data.taskIdList;
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //关闭工单请求
    async closeTaskReq() {
      try {
        let res = await closeTask({ taskIdList: this.taskIdList });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.queryList();
          this.handleClose("closeVisible");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    handleClose(name) {
      this[name] = false;
      this.unTaskIdList = [];
      this.ignoreCount = 0;
      this.taskIdList = [];
      this.closeCount = 0;
    },
    //选中数据
    handleSelectionChange(columns) {
      this.selColumn = columns;
    }
  },
  destroyed() {}
};
</script>
<style lang="scss" scoped>
.table-label {
  .label-box {
    padding: 0px 10px;
    border-radius: 20px;
    display: inline-block;
    border: 1px solid #46bfea;
    color: #46bfea;
    margin: 5px;
  }
}
</style>
