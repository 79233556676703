<!--
 * @Description: 关闭工单弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 18:37:05
 * @LastEditTime: 2022-10-18 09:13:50
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="title"
    :visible.sync="closeVisible"
    width="500px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
  >
    <div class="content">
      <e6-title>
        <div slot="tooltip">
          <i class="e6-icon-tips_line"></i>
          忽略状态已关闭或者不满足关闭条件的 {{ ignoreCount }} 个任务单
        </div>
      </e6-title>
      <div class="title">是否确认关闭勾选的{{ closeCount }} 个任务单？</div>
    </div>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button
        type="primary"
        @click="handleConfirm"
        :disabled="closeCount <= 0"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
      defauft: "关闭工单"
    },
    ignoreCount: {
      type: Number,
      defauft: 0
    },
    closeCount: {
      type: Number,
      defauft: 0
    },
    closeVisible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //点击取消
    handleClose() {
      this.$emit("handleClose", "closeVisible");
    },
    //点击确定
    handleConfirm() {
      this.$emit("confirm");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.close-dialog {
  .el-dialog__body {
    padding: 20px;
  }
}
.content {
  text-align: center;
  .title {
    margin-top: 10px;
    margin-bottom: 50px;
    text-align: center;
  }
}
</style>
